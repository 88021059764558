html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

:root {
  --footer-height: 30px;
}

.text-decoration-none {
  text-decoration: none;
}

.hoverable:hover {
  cursor: pointer;
}

.text-14 {
  font-size: 14pt;
}
