.family-picture {
  width: 50%;
  max-width: 100vw;
  min-width: 250px;
}

.welcome-text {
  font-family: 'Caveat', cursive;
  font-size: calc(24pt + 5vw);
}
